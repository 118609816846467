import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Questions } from "./quiz"

const Quiz = props => (
  <StaticQuery
    query={graphql`
      query {
        diagnoseQuestions: allSanityDiagnoseq {
          edges {
            node {
              id
              title
              weight
              position
              questionText
              answerOptions {
                answerText
                feedbackText
                posOrNeg
              }
              diagnosis {
                score
                relevantD {
                  id
                  threshold
                  title
                  description
                }
              }
              diagnosisColor {
                hex
              }
              diagnosisType
              mainImage {
                crop {
                  _key
                  _type
                  top
                  bottom
                  left
                  right
                }
                hotspot {
                  _key
                  _type
                  x
                  y
                  height
                  width
                }
                asset {
                  _id
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
                alt
              }
            }
          }
        }
        lifestyleQuestions: allSanityLifestyleq {
          edges {
            node {
              id
              title
              weight
              position
              questionText
              answerOptions {
                answerText
                feedbackText
                posOrNeg
              }
              diagnosis {
                score
                relevantD {
                  id
                  threshold
                  title
                  description
                }
              }
              diagnosisColor {
                hex
              }
              diagnosisType
              mainImage {
                crop {
                  _key
                  _type
                  top
                  bottom
                  left
                  right
                }
                hotspot {
                  _key
                  _type
                  x
                  y
                  height
                  width
                }
                asset {
                  _id
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
                alt
              }
            }
          }
        }
        diagnose: allSanityDiagnose {
          edges {
            node {
              id
              title
              description
              affiliate {
                buttonText
                buttonURL
                imageDesktop {
                  asset {
                    _id
                    fluid {
                      src
                      aspectRatio
                    }
                  }
                }
                imageMobile {
                  asset {
                    _id
                    fluid {
                      src
                      aspectRatio
                    }
                  }
                }
                text
                title
              }
              actions
              threshold
              suggestions {
                answer
                question
              }
              _rawInformation
              information {
                _type
                _key
                list
                style
                sanityChildren {
                  text
                  marks
                  _type
                  _key
                }
              }
              mainImage {
                alt
                asset {
                  _id
                  fluid {
                    src
                  }
                }
                hotspot {
                  x
                  y
                  width
                  height
                }
                crop {
                  top
                  right
                  left
                  bottom
                }
              }
              relevantArticles {
                id
                title
                articleTier
                slug {
                  current
                }
                parenttag {
                  slug {
                    current
                  }
                }
                mainImage {
                  alt
                  asset {
                    _id
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        siteSettings: allSanitySiteSettings {
          edges {
            node {
              affiliate {
                title
                link
                logo {
                  asset {
                    _id
                    fluid {
                      src
                    }
                    metadata {
                      dimensions {
                        aspectRatio
                        height
                        width
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Questions {...props} data={data} />}
  />
)

export default Quiz
