import React, { Component } from "react"
import Layout2 from "../components/main/layout2"
import Quiz from "../components/main/questionsQuery"
import styled from "styled-components"
import { CompanyLogo } from "../lib/componentHelpers"
// import Img from "gatsby-image"
// import { getFluidHeightFromAsset } from "../lib/gatsbyImageHelpers"

const QuestionsWrapper = styled.div`
  background-color: #19232d;
  width: 100%;
  padding-bottom: 2em;
  min-height: 100vh;
`

const BSLogoDiv = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AffiliateOuterDiv = styled.div`
  margin: 50px auto 0 auto;
  width: 560px;
  @media (max-width: 559px) {
    width: 375px;
    margin: 40px auto 0 auto;
  }
`

const AffiliateTitle = styled.h3`
  font-family: Overpass;
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 38px;
  text-align: center;
  color: #f8ffa0;
  @media (max-width: 559px) {
    font-size: 15px;
    line-height: 100%;
  }
`

const AffiliateInnerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
`

const AffiliateLink = styled.a`
  display: block;
  text-decoration: none;
  outline: none;
  &:active {
    outline: none;
  }
`

const DesktopContainer = styled.div`
  display: none;
  margin: 0 10px 15px 10px;
  @media (min-width: 560px) {
    display: block;
  }
`

const MobileContainer = styled.div`
  display: block;
  margin: 0 10px 15px 10px;
  @media (min-width: 560px) {
    display: none;
  }
`

const AffiliateLogo = props => {
  const {
    partner: { link, logo, title }
  } = props
  const aspectRatio = logo.asset.metadata.dimensions.aspectRatio
  const widthDesktop = `${50 * aspectRatio}px`
  const widthMobile = `${35 * aspectRatio}px`
  return (
    <AffiliateLink href={link} target="_blank" rel="noopener noreferrer">
      <DesktopContainer>
        <img
          src={logo.asset.fluid.src}
          alt={title}
          loading="lazy"
          style={{ width: widthDesktop, height: "50px" }}
        />
      </DesktopContainer>
      <MobileContainer>
        <img
          src={logo.asset.fluid.src}
          alt={title}
          loading="lazy"
          style={{ width: widthMobile, height: "35px" }}
        />
      </MobileContainer>
    </AffiliateLink>
  )
}

const AffiliateContainer = props => {
  const { affiliatePartners } = props
  return (
    <AffiliateOuterDiv>
      <AffiliateTitle>Unsere Partner</AffiliateTitle>
      <AffiliateInnerDiv>
        {affiliatePartners &&
          affiliatePartners.length > 0 &&
          affiliatePartners.map((partner, index) => (
            <AffiliateLogo partner={partner} key={index} />
          ))}
      </AffiliateInnerDiv>
    </AffiliateOuterDiv>
  )
}

class QuizQuestionsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      frameColor: "#19232d",
      currentQuestion: 0,
      totalQuestions: 0,
      height: "unset",
      width: 0,
      affiliatePartners: []
    }
  }

  setHeight = height => {
    if (height !== this.state.height) {
      this.setState({ height: height })
    }
  }

  updateState = (color, currentQuestion, totalQuestions) => {
    if (
      color !== this.state.frameColor ||
      currentQuestion !== this.state.currentQuestion ||
      totalQuestions !== this.state.totalQuestions
    ) {
      this.setState({
        frameColor: color,
        currentQuestion: currentQuestion,
        totalQuestions: totalQuestions
      })
    }
  }

  updateAffiliate = affiliate => {
    if (affiliate !== this.state.affiliatePartners) {
      this.setState({ affiliatePartners: affiliate })
    }
  }

  setWidth = () => {
    try {
      if (window) {
        this.setState({ width: `${window.innerWidth} px` })
      }
    } catch (error) {
      if (this.state.width !== 0) {
        this.setState({ width: 0 })
      }
    }
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("resize", this.setWidth)
    }
    this.setWidth()
  }

  render() {
    const { frameColor, currentQuestion, totalQuestions, affiliatePartners, width } = this.state
    return (
      <QuestionsWrapper>
        <Layout2
          frameColor={frameColor}
          currentQuestion={currentQuestion}
          totalQuestions={totalQuestions}
          width={width}
        >
          <Quiz
            updateState={this.updateState}
            setHeight={this.setHeight}
            updateAffiliate={this.updateAffiliate}
          />
        </Layout2>
        <BSLogoDiv>
          <CompanyLogo />
        </BSLogoDiv>
        {affiliatePartners && affiliatePartners.length > 0 ? (
          <AffiliateContainer affiliatePartners={affiliatePartners} />
        ) : null}
      </QuestionsWrapper>
    )
  }
}

export default QuizQuestionsPage
